export const environment = {
  project: 'webapp',
  production: true,

  envDomain: "production",
  COOKIE_LOCALE_DOMAIN: "netatmo.com",
  LEGALS_URL: "https://legals.netatmo.com/",

  prodApiUrl: 'https://app.netatmo.net',
  apiUrl: 'https://app.netatmo.net/api/',
  syncApiUrl: "https://app.netatmo.net/syncapi/v1/",
  cookiePrefix: 'netatmocom',
  cookieDomain: ".netatmo.com",
  authUrl: 'https://auth.netatmo.com/',
  logoutUrl: 'https://auth.netatmo.com/logout',
  prodAppUrl: "https://home.netatmo.com",
  appUrl: window.location.origin,
  settingsLegrandUrl: "https://settings.netatmo.com/legrand/#/",
  settingsEnergyUrl: "https://settings.netatmo.com/energy/",
  settingsWeatherUrl: "https://settings.netatmo.com/weather/",
  myUrl: "https://my.netatmo.com",
  helpcenterUrl: "https://helpcenter.netatmo.com/hc/",
  myAccountUrl: "https://myconnectedaccount.netatmo.com/",
  langsUrl: '/langs/',
  termsAndCondtionsUrl: 'https://view.netatmo.com/',
  legalsUrl: 'https://legals.netatmo.com/',
  env: 'prod',

  webRTCSocketUrl: 'wss://app-ws.netatmo.net/appws/',
  webPusherUrl: 'wss://app-ws.netatmo.net/ws/',

  turnUrl: 'https://app-turn.netatmo.net/api/',
  weatherMapUrl: "https://weathermap.netatmo.com",
  shopUrl : "https://shop.netatmo.com/"
} as Env;
